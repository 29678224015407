import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { TextSection as Props } from '@type-declarations/sections';

import styles from './TextSection.module.scss';

function TextSection({
  title,
  subtitle,
  text,
  cta,
  backgroundColor,
  modifier,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
        centered
        className={styles.content}
        modifier={modifier === 'inStore' ? 'inStore' : undefined}
      />
    </Section>
  );
}

export default TextSection;
