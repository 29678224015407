import { FaqItem as FaqItemType } from '@type-declarations/faq';
import clsx from 'clsx';
import { useState } from 'react';

import FaqItem from './FaqItem';
import styles from './FaqList.module.scss';

interface Props {
  faqList: FaqItemType[];
  firstQuestionOpen?: boolean;
  altBackground: boolean;
}

function FaqList({ faqList, firstQuestionOpen = false, altBackground }: Props) {
  const [expandedIndex, setExpandedIndex] = useState<number>(
    firstQuestionOpen ? 0 : -1
  );

  return (
    <dl
      className={clsx(
        styles.wrapper,
        'u-list-clean u-grid u-grid--gap-0',
        altBackground && styles.altBackground
      )}
    >
      {faqList.map((item, i) => (
        <FaqItem
          item={item}
          key={item.id}
          id={item.id}
          isOpen={expandedIndex === i}
          handleAccordion={() => setExpandedIndex(i === expandedIndex ? -1 : i)}
        />
      ))}
    </dl>
  );
}

export default FaqList;
