import Carousel from '@molecules/Carousel/Carousel';
import ImageSliderItem from '@molecules/ImageSliderItem/ImageSliderItem';
import Section from '@molecules/Section/Section';
import { ImageSliderSection as Props } from '@type-declarations/sections';

import styles from './ImageSliderSection.module.scss';

export default function ImageSliderSection({ images, backgroundColor }: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={styles.wrapper}>
        <Carousel
          items={images}
          SlideItem={ImageSliderItem}
          slidesPerView={{ mobile: 1, tablet: 2.25, desktop: 3 }}
        />
      </div>
    </Section>
  );
}
