import 'swiper/css';

import Button from '@atoms/Button/Button';
import { Locale } from '@type-declarations/locale';
import { StoreModifier } from '@type-declarations/modifier';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import styles from './Carousel.module.scss';

type CarouselItem<T> = { id: string } & T;

interface Props<T> {
  items: CarouselItem<T>[];
  SlideItem: (props: CarouselItem<T>) => JSX.Element;
  slidesPerView:
    | {
        mobile: number;
        tablet: number;
        desktop: number;
      }
    | number;
  modifier?: StoreModifier;
}

const navigationLabels = {
  nl: {
    previous: 'Vorige slide',
    next: 'Volgende slide',
  },
  en: {
    previous: 'Previous slide',
    next: 'Next slide',
  },
};

const NEXT = 'next';
const PREVIOUS = 'previous';

// TODO: Add buttons for Store
export default function Carousel<T>({
  items,
  SlideItem,
  slidesPerView,
  modifier,
}: Props<T>) {
  const locale = useRouter().locale as Locale;

  const prevRef = useRef();
  const nextRef = useRef();

  const [swiperClass, setSwiperClass] = useState<SwiperClass | null>(null);

  const [index, setIndex] = useState(0);

  const handleNavigation = (action: typeof NEXT | typeof PREVIOUS) => {
    if (action === PREVIOUS) {
      swiperClass?.slidePrev();
    } else if (action === NEXT) {
      swiperClass?.slideNext();
    }
  };

  const handleSlide = ({ activeIndex }: SwiperClass) => setIndex(activeIndex);

  const desktopSlidesPerView =
    typeof slidesPerView === 'number' ? slidesPerView : slidesPerView.desktop;

  const previousButtonIsVisible = modifier ? true : index > 0;
  const nextButtonIsVisible = modifier
    ? true
    : index < items.length - desktopSlidesPerView;

  return (
    <div className={clsx(styles.wrapper, modifier && styles[modifier])}>
      <Swiper
        modules={[Navigation]}
        navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
        onSwiper={setSwiperClass}
        className={styles.swiper}
        initialSlide={0}
        onResize={handleSlide}
        onSlideChange={handleSlide}
        breakpoints={{
          320: {
            spaceBetween: 12,
            slidesPerView:
              typeof slidesPerView === 'number'
                ? slidesPerView
                : slidesPerView.mobile,
          },
          640: {
            spaceBetween: 24,
            slidesPerView:
              typeof slidesPerView === 'number'
                ? slidesPerView
                : slidesPerView.tablet,
          },
          1280: {
            spaceBetween: 24,
            slidesPerView: desktopSlidesPerView,
          },
        }}
      >
        {
          // eslint-disable-next-line react/prop-types
          items.map((item, itemIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${item.id}-${itemIndex}`}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <SlideItem {...item} modifier={modifier} showSaveButton />
            </SwiperSlide>
          ))
        }
      </Swiper>

      {items.length > desktopSlidesPerView && (
        <>
          {previousButtonIsVisible && (
            <Button
              label={navigationLabels[locale].previous}
              variant="white"
              icon="NAV_ARROW_LEFT"
              hideLabel
              rounded
              onClick={() => handleNavigation(PREVIOUS)}
              className={clsx(styles.button, styles.previous)}
            />
          )}

          {nextButtonIsVisible && (
            <Button
              label={navigationLabels[locale].next}
              variant="white"
              icon="NAV_ARROW_RIGHT"
              hideLabel
              rounded
              onClick={() => handleNavigation(NEXT)}
              className={clsx(styles.button, styles.next)}
            />
          )}
        </>
      )}
    </div>
  );
}
