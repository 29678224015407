import Figure from '@atoms/Figure/Figure';
import RichText from '@atoms/RichText/RichText';
import Section from '@molecules/Section/Section';
import { QuoteSection as Props } from '@type-declarations/sections';

import styles from './QuoteSection.module.scss';

function QuoteSection({
  quote,
  name,
  image,
  functionName,
  backgroundColor,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={styles.wrapper}>
        <h2 className={styles.quote}>{quote}</h2>
        {(functionName || name || image) && (
          <div className={styles.figureContent}>
            {(functionName || name) && (
              <div className={styles.nameWrapper}>
                {name && <h3 className={styles.name}>{name}</h3>}
                {functionName && (
                  <RichText
                    text={functionName}
                    className={styles.functionName}
                  />
                )}
              </div>
            )}
            {image?.url && (
              <Figure
                image={image}
                alt={name}
                sizes="(min-width: 64px) 64px, (min-width: 64px) 64px"
                imageClassName={styles.image}
                className={styles.image}
              />
            )}
          </div>
        )}
      </div>
    </Section>
  );
}

export default QuoteSection;
