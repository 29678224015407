import CtaButton from '@molecules/CtaButton/CtaButton';
import SectionContent from '@molecules/Section/SectionContent';
import SectionWrapper from '@molecules/Section/SectionWrapper';
import { CtaButtonsSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './CtaButtonsSection.module.scss';

function CtaButtonsSection({
  title,
  text,
  ctaButtons,
  backgroundColor,
  modifier,
}: Props) {
  const buttons = ctaButtons.flatMap(button => button.ctaButtons);

  return (
    <SectionWrapper
      isHeroSection={modifier === 'heroSection'}
      backgroundColor={backgroundColor}
    >
      <SectionContent title={title} text={text} className={styles.content} />

      <ul
        className={clsx(
          styles.ctas,
          'u-list-clean u-grid u-grid--gap-2 u-grid--2@sm u-grid--3@lg u-grid--4@xl'
        )}
      >
        {buttons.map(button => (
          <li className={styles.item} key={button.id}>
            <CtaButton variant="white" cta={button} fullWidth />
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
}

export default CtaButtonsSection;
