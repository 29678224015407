import NewsletterSignup from '@molecules/Newsletter/NewsletterSignup';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { NewsletterSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './NewsletterSection.module.scss';

function NewsletterSection({
  title,
  text,
  containerColor,
  backgroundColor,
  formId,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={clsx(styles.wrapper, styles[containerColor])}>
        <SectionContent className={styles.content} title={title} text={text} />
        <div className={styles.formContent}>
          <NewsletterSignup formId={formId} />
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
