import { Error } from '@type-declarations/error';
import { useState } from 'react';

export function useFormErrors() {
  const [errors, setErrors] = useState<Error[]>([]);

  const getError = (key: string) => errors.find(error => error.key === key);

  const clearError = (key: string) => {
    const error = getError(key);
    if (!error) return;

    setErrors(errors.filter(e => e !== error));
  };

  return { errors, setErrors, getError, clearError };
}
