import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { SectionBackgroundColor } from '@type-declarations/color';
import { Cta } from '@type-declarations/cta';
import { Image as ImageType } from '@type-declarations/media';
import Image from 'next/legacy/image';

import styles from './StoreMapCtaSection.module.scss';

export interface Props {
  title: string;
  text: string;
  image: ImageType;
  backgroundColor: SectionBackgroundColor;
  cta: Cta;
}

export default function StoreMapCtaSection({
  backgroundColor,
  title,
  text,
  cta,
  image,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <Image
            src={image.url}
            placeholder="blur"
            blurDataURL={image.placeholder}
            layout="fill"
            alt=""
            objectFit="cover"
          />
        </div>
        <SectionContent
          title={title}
          text={text}
          cta={cta}
          className={styles.content}
          modifier="inStore"
        />
      </div>
    </Section>
  );
}
