import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { BackgroundColor } from '@type-declarations/color';
import { Cta } from '@type-declarations/cta';
import { StoreModifier } from '@type-declarations/modifier';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './TextMediaWrapper.module.scss';

interface Props {
  title?: string;
  subtitle?: string;
  text: string;
  cta?: Cta;
  backgroundColor: BackgroundColor;
  textLeft?: boolean;
  modifier?: StoreModifier;
}

function TextMediaWrapper({
  title,
  subtitle,
  text,
  cta,
  backgroundColor,
  textLeft = true,
  children,
  modifier,
}: PropsWithChildren<Props>) {
  return (
    <Section
      backgroundColor={backgroundColor}
      containerClassName={clsx(
        styles.wrapper,
        !textLeft && styles.textRight,
        modifier && styles[modifier]
      )}
    >
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
        className={styles.content}
        modifier={modifier}
      />
      <div className={styles.media}>{children}</div>
    </Section>
  );
}

export default TextMediaWrapper;
