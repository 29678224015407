import AccessibilitySection from '@organisms/AccessibilitySection/AccessibilitySection';
import ContentCarouselSection from '@organisms/ContentCarouselSection/ContentCarouselSection';
import CtaButtonsSection from '@organisms/CtaButtonsSection/CtaButtonsSection';
import EventCarouselSection from '@organisms/EventCarouselSection/EventCarouselSection';
import EventRegistrationSection from '@organisms/EventRegistrationSection/EventRegistrationSection';
import ExploreFormSection from '@organisms/ExploreFormSection/ExploreFormSection';
import FaqSection from '@organisms/FaqSection/FaqSection';
import IframeSection from '@organisms/IframeSection/IframeSection';
import ImageSection from '@organisms/ImageSection/ImageSection';
import ImageSliderSection from '@organisms/ImageSliderSection/ImageSliderSection';
import MapSection from '@organisms/MapSection/MapSection';
import NewsletterSection from '@organisms/NewsletterSection/NewsletterSection';
import QuoteSection from '@organisms/QuoteSection/QuoteSection';
import SegmentationSection from '@organisms/SegmentationSection/SegmentationSection';
import StoreMapCtaSection from '@organisms/Store/MapCtaSection/StoreMapCtaSection';
import TextImageSection from '@organisms/TextImageSection/TextImageSection';
import TextSection from '@organisms/TextSection/TextSection';
import TextVideoSection from '@organisms/TextVideoSection/TextVideoSection';
import VideoSection from '@organisms/VideoSection/VideoSection';

export const SECTIONS = {
  ImageSection,
  TextImageSection,
  TextSection,
  TextVideoSection,
  VideoSection,
  CtaButtonsSection,
  AccessibilitySection,
  ImageSliderSection,
  FaqSection,
  QuoteSection,
  EventRegistrationSection,
  ContentCarouselSection,
  ExploreFormSection,
  EventCarouselSection,
  NewsletterSection,
  StoreMapCtaSection,
  IframeSection,
  SegmentationSection,
  MapSection,
};
