import { BG_DEFAULT } from '@constants/constants';
import FaqList from '@molecules/FaqList/FaqList';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { FaqSection as Props } from '@type-declarations/sections';

function FaqSection({
  title,
  subtitle,
  text,
  cta,
  faqList,
  firstQuestionOpen,
  backgroundColor,
  modifier,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
        modifier={modifier === 'inStore' ? modifier : undefined}
      />
      <FaqList
        faqList={faqList}
        firstQuestionOpen={firstQuestionOpen}
        altBackground={backgroundColor.color === BG_DEFAULT}
      />
    </Section>
  );
}

export default FaqSection;
