import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { IframeSection as Props } from '@type-declarations/sections';
import { useEffect, useState } from 'react';

import styles from './IframeSection.module.scss';

function IframeSection({
  title,
  text,
  iframe,
  backgroundColor,
  modifier,
}: Props) {
  const [iframeState, setIframeState] = useState<string>('');

  useEffect(() => {
    setIframeState(
      iframe
        .replaceAll(/“/g, '"')
        .replaceAll(/”/g, '"')
        .replaceAll(/‘/g, "'")
        .replaceAll(/’/g, "'")
    );
  }, [iframe]);

  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title}
        text={text}
        className={styles.content}
        centered
        modifier={modifier === 'inStore' ? modifier : undefined}
      />

      {/* Renders only client-side */}
      {!!iframeState && (
        <div
          className={styles.container}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: iframeState }}
        />
      )}
    </Section>
  );
}

export default IframeSection;
