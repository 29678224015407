import Button from '@atoms/Button/Button';
import SelectInput from '@atoms/Inputs/SelectInput';
import useForm from '@hooks/useForm';
import useStore from '@hooks/useStore';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { ExploreTag } from '@type-declarations/prepr';
import { ExploreFormSection as Props } from '@type-declarations/sections';
import { useRouter } from 'next/router';
import { ChangeEvent, FormEvent, useMemo } from 'react';

import styles from './ExploreFormSection.module.scss';

const splitOptions = (options: ExploreTag[]) => ({
  period: options.filter(({ type }) => type === 'period'),
  companion: options.filter(({ type }) => type === 'companion'),
  transport: options.filter(({ type }) => type === 'transport'),
  weather: options.filter(({ type }) => type === 'weather'),
});

const mapOptions = (options: ExploreTag[]) =>
  options.map(({ slug, title }) => ({
    value: slug,
    label: title,
  }));

const translations = {
  nl: {
    1: 'In',
    2: 'met',
    3: 'Ik kom',
    4: 'als',
  },
  en: {
    1: 'In',
    2: 'with',
    3: 'I come',
    4: 'when',
  },
};

export default function ExploreFormSection({
  id,
  title,
  ctaLabel,
  backgroundColor,
}: Props) {
  const { query, push } = useRouter();
  const {
    store: { locale, page },
  } = useStore();

  const { period, companion, transport, weather } = useMemo(
    () =>
      splitOptions(
        page?.additionalSectionData?.[id]?.ExploreTags?.items ||
          page?.exploreTags ||
          []
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, page?.exploreTags]
  );

  const {
    inputs,
    handleChange,
  }: {
    inputs: {
      period?: string;
      companion?: string;
      transport?: string;
      weather?: string;
    };
    handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  } = useForm({
    period: period[0]?.slug,
    companion: companion[0]?.slug,
    transport: transport[0]?.slug,
    weather: weather[0]?.slug,
    ...query,
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    push({ pathname: 'explore', query: { ...inputs, page: 1 } });
  };

  const nlfSelect = (key: keyof typeof inputs, options: ExploreTag[]) => (
    <span className={styles.selectWrapper}>
      <SelectInput
        fitContent
        id={`nlf-${key}-select`}
        label={key}
        name={key}
        value={inputs[key]}
        onChange={handleChange}
        options={mapOptions(options)}
      />
    </span>
  );

  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={styles.container}>
        <SectionContent title={title} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.question}>
            <span>
              {translations[locale][1]} {nlfSelect('period', period)}{' '}
            </span>
            <span>
              {translations[locale][2]} {nlfSelect('companion', companion)}.
            </span>
            <span>
              {'  '}
              {translations[locale][3]} {nlfSelect('transport', transport)}{' '}
            </span>
            <span>
              {translations[locale][4]} {nlfSelect('weather', weather)}
            </span>
          </div>

          <Button
            label={ctaLabel}
            submit
            className={styles.submit}
            iconLeft="MAP"
            icon="NAV_ARROW_RIGHT"
          />
        </form>
      </div>
    </Section>
  );
}
