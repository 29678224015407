import Icon from '@atoms/Icon/Icon';
import RichText from '@atoms/RichText/RichText';
import type { FaqItem as FaqItemType } from '@type-declarations/faq';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './FaqItem.module.scss';

interface Props {
  item: FaqItemType;
  id: string;
  isOpen: boolean;
  handleAccordion: () => void;
}

function FaqItem({ item, id, isOpen, handleAccordion }: Props) {
  const toggleId = `${id}-toggle`;
  const panelId = `${id}-panel`;
  const icon = isOpen ? 'MINUS' : 'PLUS';

  const [itemHeight, setItemHeight] = useState<string>('0');
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setItemHeight(`${textRef?.current?.offsetHeight || 0}px`);
  }, [isOpen]);

  const style = { '--faq-height': itemHeight } as React.CSSProperties;

  return (
    <div className={styles.faqItem}>
      <dt className={styles.header}>
        <button
          id={toggleId}
          onClick={handleAccordion}
          className={styles.accordionButton}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls={panelId}
          type="button"
        >
          {item.title}
          <span className={styles.iconContainer} aria-hidden="true">
            <Icon className={styles.svgIcon} icon={icon} />
          </span>
        </button>
      </dt>
      <div id={panelId} style={style}>
        <CSSTransition
          in={!!isOpen}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div className={styles.textWrapper}>
            <dd
              aria-labelledby={toggleId}
              className={clsx(styles.text, !!isOpen && styles.showText)}
              ref={textRef}
            >
              <RichText text={item.text} className={styles.richText} />
            </dd>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default FaqItem;
