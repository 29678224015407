import { BG_DEFAULT } from '@constants/constants';
import { BackgroundColor } from '@type-declarations/color';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Section.module.scss';

interface Props {
  id?: string;
  backgroundColor?: BackgroundColor;
  containerClassName?: string | false;
}

export default function Section({
  backgroundColor = BG_DEFAULT,
  containerClassName,
  children,
  id,
}: PropsWithChildren<Props>) {
  return (
    <section
      id={id}
      className={clsx(styles.section, styles[backgroundColor])}
      data-section
    >
      <div className={clsx('u-container', containerClassName)}>{children}</div>
    </section>
  );
}
