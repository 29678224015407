import { STORE_DISABLED_SECTIONS } from '@constants/constants';
import { SECTIONS } from '@constants/sections';
import {
  HeroSectionModifier,
  StoreModifier,
} from '@type-declarations/modifier';
import { Section } from '@type-declarations/sections';

interface Props {
  sections: Section[];
  modifier?: StoreModifier | HeroSectionModifier;
}

function Sections({ sections, modifier }: Props) {
  if (!sections.length) return null;

  return sections.map(section => {
    if (
      modifier === 'inStore' &&
      STORE_DISABLED_SECTIONS.includes(section.typename)
    )
      return null;

    // TODO: REMOVE ANY TYPE. Make sure we have the correct SectionProps instead of a generic Section type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component: any = SECTIONS[section?.typename];
    if (!Component) return null;

    return (
      <Component
        key={`${section.id}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...section}
        modifier={modifier}
      />
    );
  });
}

export default Sections;
