import EventForm from '@molecules/EventForm/EventForm';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { EventRegistrationSection as Props } from '@type-declarations/sections';

function EventRegistrationSection({
  id,
  title,
  subtitle,
  text,
  backgroundColor,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent title={title} subtitle={subtitle} text={text} centered>
        <EventForm id={id} />
      </SectionContent>
    </Section>
  );
}

export default EventRegistrationSection;
