import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Fieldset.module.scss';

interface Props extends PropsWithChildren {
  legend: string;
  className?: string;
}

export default function Fieldset({ legend, className, children }: Props) {
  return (
    <fieldset className={clsx(styles.fieldset, className)}>
      <legend className={clsx(styles.legend, 'u-typography-heading-4')}>
        {legend}
      </legend>
      {children}
    </fieldset>
  );
}
