import Button from '@atoms/Button/Button';
import DatePicker from '@atoms/DatePicker/DatePicker';
import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import Fieldset from '@atoms/Fieldset/Fieldset';
import FileUpload from '@atoms/Inputs/FileUpload';
import TextInput from '@atoms/Inputs/TextInput';
import { ROUTES } from '@constants/constants';
import { useFormErrors } from '@hooks/useErrors';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import { toLowerCamelCase } from '@utils/formatString';
import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import { ZodIssue } from 'zod';

import styles from './EventForm.module.scss';

interface Props {
  id: string;
}

export default function EventForm({ id }: Props) {
  const {
    store: { locale },
  } = useStore();
  const router = useRouter();
  const t = useTranslations();

  const [isLoading, setIsLoading] = useState(false);
  const { setErrors, getError, clearError } = useFormErrors();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = new FormData(e.currentTarget);

    const response = await fetch('/api/register-event', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 422) {
        setErrors(
          (await response.json()).errors.map((error: ZodIssue) => ({
            key: error.path[0],
            message:
              (t as { [key: string]: string })[
                `${toLowerCamelCase(error.message)}`
              ] || error.message,
          }))
        );
      }

      setErrors([{ key: 'server', message: t.somethingWentWrong }]);
    } else {
      router.push(`/${ROUTES.EventCreatedPage[locale]}`);
    }

    setIsLoading(false);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <input type="hidden" name="locale" value={locale} />

      <Fieldset legend={t.eventInformation}>
        <TextInput
          required
          label={t.eventName}
          name="title"
          id={`${id}-name`}
          error={getError('title')}
          onChange={() => clearError('title')}
        />
        <TextInput
          type="textarea"
          required
          label={t.description}
          name="description"
          id={`${id}-description`}
          error={getError('description')}
          onChange={() => clearError('description')}
        />
        <TextInput
          label={t.price}
          name="price"
          id={`${id}-price`}
          type="textarea"
        />
      </Fieldset>
      <Fieldset legend={t.dateAndTime}>
        <p>{t.dateAndTimeFieldsInformation}</p>
        <DatePicker
          label={t.eventStart}
          id={`${id}-start`}
          name="startDate"
          options={{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:S',
            altInput: true,
            altFormat: 'd-m-Y H:i',
          }}
          required
        />
        <DatePicker
          label={t.eventEnd}
          id={`${id}-end`}
          name="endDate"
          options={{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:S',
            altInput: true,
            altFormat: 'd-m-Y H:i',
          }}
          required
        />
      </Fieldset>
      <Fieldset legend={t.eventLocationTitle}>
        <div className={styles.streetGroup}>
          <TextInput
            wrapperClassName={styles.street}
            label={t.street}
            name="street"
            id={`${id}-street`}
          />
          <TextInput
            label={t.streetNumber}
            name="streetNumber"
            id={`${id}-streetNumber`}
            error={getError('streetNumber')}
            onChange={() => clearError('streetNumber')}
          />
          <TextInput
            label={t.streetNumberSuffix}
            name="streetNumberSuffix"
            id={`${id}-streetNumberSuffix`}
          />
        </div>
        <div className={styles.cityGroup}>
          <TextInput label={t.zipcode} name="zipcode" id={`${id}-zipcode`} />
          <TextInput
            required
            label={t.city}
            name="city"
            id={`${id}-city`}
            defaultValue="Breda"
            error={getError('city')}
            onChange={() => clearError('city')}
          />
        </div>
        <TextInput
          defaultValue="NL"
          label={t.country}
          name="country"
          id={`${id}-country`}
        />
        <TextInput
          required
          label={t.locationName}
          name="locationName"
          id={`${id}-locationName`}
          error={getError('locationName')}
          onChange={() => clearError('locationName')}
        />
      </Fieldset>
      <Fieldset legend={t.contactInformation}>
        <TextInput label={t.website} name="website" id={`${id}-website`} />
        <TextInput
          label={t.email}
          name="email"
          type="email"
          id={`${id}-email`}
          error={getError('email')}
          onChange={() => clearError('email')}
        />
        <TextInput label={t.phone} name="phone" id={`${id}-phone`} />
      </Fieldset>
      <Fieldset legend={t.addImage}>
        <FileUpload
          label="Selecteer een bestand"
          helpText="Max. grootte: 20 MB"
          maxSizeInMb={20}
          name="image"
          id="image"
        />
      </Fieldset>
      <Fieldset legend={t.yourDetails}>
        <TextInput
          required
          label={t.name}
          name="contactName"
          id={`${id}-contactName`}
          error={getError('contactName')}
          onChange={() => clearError('contactName')}
        />
        <TextInput
          required
          label={t.email}
          name="contactEmail"
          id={`${id}-contactEmail`}
          type="email"
          error={getError('contactEmail')}
          onChange={() => clearError('contactEmail')}
        />
        <TextInput
          required
          label={t.phone}
          name="contactPhone"
          id={`${id}-contactPhone`}
          error={getError('contactPhone')}
          onChange={() => clearError('contactPhone')}
        />
      </Fieldset>
      <Fieldset legend={t.additionalDetails}>
        <TextInput
          type="textarea"
          label={t.comments}
          name="comments"
          id={`${id}-comments`}
        />
      </Fieldset>

      <Button label={t.submitEvent} submit loading={isLoading} />

      <ErrorMessage message={getError('server')?.message} />
    </form>
  );
}
