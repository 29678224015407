import Figure from '@atoms/Figure/Figure';
import { Image as ImageType } from '@type-declarations/media';

import styles from './ImageSliderItem.module.scss';

export default function ImageSliderItem({
  id,
  url,
  width,
  height,
  placeholder,
  caption,
  author,
}: ImageType) {
  return (
    <Figure
      image={{ id, url, placeholder, width, height }}
      alt={caption}
      author={author}
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      className={styles.figure}
      quality={100}
    />
  );
}
