import RichText from '@atoms/RichText/RichText';
import { Cta } from '@type-declarations/cta';
import { StoreModifier } from '@type-declarations/modifier';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import CtaButton from '../CtaButton/CtaButton';
import styles from './SectionContent.module.scss';

interface SectionContentProps extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  text?: string;
  cta?: Cta;
  centered?: boolean;
  addSpaceForButtons?: boolean;
  className?: string;
  modifier?: StoreModifier | 'fullWidth';
}

function SectionContent({
  title,
  subtitle,
  text,
  cta,
  centered,
  className,
  children,
  modifier,
  addSpaceForButtons,
}: SectionContentProps) {
  if (!title && !subtitle && !text && !cta?.title && !children) return null;

  return (
    <div
      className={clsx(
        styles.container,
        centered && styles.centered,
        modifier && styles[modifier],
        addSpaceForButtons && styles.addSpaceForButtons,
        className
      )}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {text && (
        <RichText
          text={text}
          className={styles.text}
          store={modifier === 'inStore'}
        />
      )}
      {cta &&
        (modifier !== 'inStore' ||
          cta.internalLink[0].typename === 'StoreMapPage') && (
          <CtaButton
            cta={cta}
            modifier={modifier === 'inStore' ? modifier : undefined}
          />
        )}
      {children}
    </div>
  );
}

export default SectionContent;
