import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import InteractiveMap from '@organisms/InteractiveMap/InteractiveMap';
import { MapSection as MapSectionProps } from '@type-declarations/sections';

function MapSection({
  title,
  subtitle,
  text,
  cta,
  locations,
}: MapSectionProps) {
  return (
    <Section>
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
        addSpaceForButtons
      >
        <div className="u-mt-8">
          <InteractiveMap
            maxClusterRadius={40}
            locations={locations
              .filter(location => !!location.coordinates)
              ?.map(location => ({
                ...location,
                icon:
                  location.category?.[0]?.icon ||
                  location.category?.[0]?.category?.[0]?.icon,
                pos: [
                  Number(location.coordinates.latitude),
                  Number(location.coordinates.longitude),
                ],
              }))}
          />
        </div>
      </SectionContent>
    </Section>
  );
}

export default MapSection;
