import Button from '@atoms/Button/Button';
import useStore from '@hooks/useStore';
import { ButtonVariants, Cta } from '@type-declarations/cta';
import { StoreModifier } from '@type-declarations/modifier';
import getInternalRoute from '@utils/getInternalRoute';

interface Props {
  cta: Cta;
  className?: string;
  ctaAnimation?: boolean;
  fullWidth?: boolean;
  variant?: ButtonVariants;
  modifier?: StoreModifier;
}

function CtaButton({
  cta,
  className,
  variant = 'primary',
  fullWidth = false,
  ctaAnimation = true,
  modifier,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  if (!cta || !cta.title) return null;

  let ctaLink;
  if (cta.internalLink[0]) {
    ctaLink = getInternalRoute({ internalLink: cta.internalLink[0], locale });
  } else if (cta.externalLink) {
    ctaLink = cta.externalLink;
  } else if (cta.file?.url) {
    ctaLink = `${cta.file.url}?cd=inline`;
  }

  if (!ctaLink) return null;

  return (
    <Button
      href={ctaLink}
      label={cta.title}
      icon="NAV_ARROW_RIGHT"
      iconLeft={cta.icon}
      className={className}
      noAnimation={!ctaAnimation}
      variant={variant}
      fullWidth={fullWidth}
      modifier={modifier}
    />
  );
}

export default CtaButton;
