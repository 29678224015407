import Icon, { IconType } from '@atoms/Icon/Icon';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { Locale } from '@type-declarations/locale';
import { AccessibilitySection as Props } from '@type-declarations/sections';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import styles from './AccessibilitySection.module.scss';

type Type =
  | 'general'
  | 'guideDog'
  | 'hearingImpaired'
  | 'visuallyImpaired'
  | 'walkingDifficulties';

const icons = {
  general: 'accessibility',
  guideDog: 'wolf',
  hearingImpaired: 'closed-captions-tag',
  visuallyImpaired: 'eye',
  walkingDifficulties: 'accessibility-sign',
};

const translations = {
  nl: {
    title: 'Toegankelijkheid',
    general: 'Algemeen',
    guideDog: 'Geleidehond toegestaan',
    hearingImpaired: 'Toegankelijk voor slechthorenden',
    visuallyImpaired: 'Toegankelijk voor slechtzienden',
    walkingDifficulties: 'Toegankelijk voor rolstoelen',
  },
  en: {
    title: 'Accessibility',
    general: 'General',
    guideDog: 'Guide dog allowed',
    hearingImpaired: 'Accessible for the hearing impaired',
    visuallyImpaired: 'Accessible for the visually impaired',
    walkingDifficulties: 'Accessible for wheelchairs',
  },
};

function AccessibilitySection({
  title,
  text,
  guideDog,
  hearingImpaired,
  general,
  visuallyImpaired,
  walkingDifficulties,
  backgroundColor,
  modifier,
}: Props) {
  const locale = useRouter().locale as Locale;

  const items = {
    guideDog,
    hearingImpaired,
    general,
    visuallyImpaired,
    walkingDifficulties,
  };

  const isAccessible = Object.values(items).filter(x => !!x).length > 0;

  if (!isAccessible) return null;

  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title || translations[locale].title}
        text={text}
        centered
        className={styles.content}
        modifier={modifier === 'inStore' ? 'inStore' : undefined}
      >
        <ul
          className={clsx(
            styles.wrapper,
            modifier && styles[modifier],
            'u-list-clean u-grid u-grid--gap-0 u-grid--gap-6@sm u-grid--2@sm'
          )}
        >
          {Object.entries(items).map(([key, value]) => {
            if (!value) return null;

            const icon = icons[key as Type] as IconType;
            const translation = translations[locale][key as Type];

            return (
              <li className={styles.item} key={key}>
                {icon && (
                  <span
                    className={clsx(styles.iconContainer)}
                    aria-hidden="true"
                  >
                    <Icon className={clsx(styles.svgIcon)} icon={icon} />
                  </span>
                )}
                {translation}
              </li>
            );
          })}
        </ul>
      </SectionContent>
    </Section>
  );
}

export default AccessibilitySection;
