import type { MuxPlayerRefAttributes } from '@mux/mux-player-react';
import { RefObject, useState } from 'react';

const useVideo = ({
  reference,
}: {
  reference: RefObject<MuxPlayerRefAttributes>;
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const play = () => {
    reference.current?.play();
    setIsVideoPlaying(true);
  };

  const pause = () => {
    reference.current?.pause();
    setIsVideoPlaying(false);
  };

  return { isVideoPlaying, play, pause };
};

export default useVideo;
