import useStore from '@hooks/useStore';
import Card from '@molecules/Card/Card';
import Carousel from '@molecules/Carousel/Carousel';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { Event } from '@type-declarations/overviewItems';
import { EventCarouselSection as Props } from '@type-declarations/sections';
import getInternalRoute from '@utils/getInternalRoute';

import styles from './EventCarouselSection.module.scss';

export default function EventCarouselSection({
  id,
  title,
  subtitle,
  text,
  backgroundColor,
  selection,
  modifier,
}: Props) {
  const {
    store: { page, locale },
  } = useStore();

  const items: Event[] =
    page?.additionalSectionData?.[id]?.EventPages?.items || [];

  const carouselItems = [...selection, ...items].map(item => ({
    ...item,
    href: getInternalRoute({
      internalLink: item,
      locale,
      store: modifier === 'inStore',
    }),
    showType: true,
  }));

  if (!carouselItems?.length) return null;

  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        modifier={modifier === 'inStore' ? 'inStore' : undefined}
        addSpaceForButtons={modifier === 'inStore'}
      />

      <div className={styles.wrapper}>
        <Carousel
          items={carouselItems}
          SlideItem={Card}
          slidesPerView={
            modifier === 'inStore'
              ? 2.5
              : { mobile: 1, tablet: 3.25, desktop: 4 }
          }
          modifier={modifier === 'inStore' ? 'inStore' : undefined}
        />
      </div>
    </Section>
  );
}
