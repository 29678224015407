import useStore from '@hooks/useStore';
import Card from '@molecules/Card/Card';
import Carousel from '@molecules/Carousel/Carousel';
import SectionContent from '@molecules/Section/SectionContent';
import SectionWrapper from '@molecules/Section/SectionWrapper';
import { OverviewItem } from '@type-declarations/overviewItems';
import { ContentCarouselSection as Props } from '@type-declarations/sections';
import getInternalRoute from '@utils/getInternalRoute';

import styles from './ContentCarouselSection.module.scss';

export default function ContentCarouselSection({
  id,
  title,
  subtitle,
  text,
  imageSize,
  cardSize,
  backgroundColor,
  selection,
  modifier,
}: Props) {
  const {
    store: { page, locale },
  } = useStore();

  const items: OverviewItem[] =
    page?.additionalSectionData?.[id]?.ContentItems?.items || [];

  const carouselItems = [...selection, ...items].map(item => ({
    ...item,
    href: getInternalRoute({
      internalLink: item,
      locale,
      store: modifier === 'inStore',
    }),
    showType: true,
    imageSize: imageSize || 'md',
  }));

  if (!carouselItems?.length) return null;

  const slidesPerView = {
    sm: { mobile: 1, tablet: 3.25, desktop: 4 },
    md: { mobile: 1, tablet: 2.5, desktop: 3 },
    lg: { mobile: 1, tablet: 1.5, desktop: 2 },
  };

  return (
    <SectionWrapper
      isHeroSection={modifier === 'heroSection'}
      backgroundColor={backgroundColor}
    >
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        modifier={modifier === 'inStore' ? 'inStore' : undefined}
        addSpaceForButtons={modifier === 'inStore'}
      />

      <div className={styles.wrapper}>
        <Carousel
          items={carouselItems}
          SlideItem={Card}
          slidesPerView={
            modifier === 'inStore' ? 2.5 : slidesPerView[cardSize || 'sm']
          }
          modifier={modifier === 'inStore' ? 'inStore' : undefined}
        />
      </div>
    </SectionWrapper>
  );
}
