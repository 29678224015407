import { SectionBackgroundColor } from '@type-declarations/color';
import { PropsWithChildren } from 'react';

import Section from './Section';

export default function SectionWrapper({
  children,
  isHeroSection,
  backgroundColor,
}: PropsWithChildren<{
  isHeroSection: boolean;
  backgroundColor: SectionBackgroundColor;
}>) {
  if (isHeroSection) return children;

  return <Section backgroundColor={backgroundColor?.color}>{children}</Section>;
}
