import useTranslations from '@hooks/useTranslations';
import { MapLocation } from '@type-declarations/prepr';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';

import styles from './LeafletMap.module.scss';

function Loading() {
  const { loadingMap } = useTranslations();
  return <p className={styles.map}>{loadingMap}</p>;
}

const RESPONSE_READY = 4;
const STATUS_OK = 200;

function loadXMLDoc(url: string, resolve: (data: [number, number][]) => void) {
  const xmlhttp = new XMLHttpRequest();

  xmlhttp.onreadystatechange = function parseXML() {
    if (this.readyState === RESPONSE_READY && this.status === STATUS_OK) {
      /* Parse XML from response (string) */
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.response, 'text/xml');

      /* Get all <trkpt> with lat & long values */
      const trackPoints = xmlDoc.getElementsByTagName('trkpt');

      /* Store all lat & lon values in array:  [number, number][] */
      const data: [number, number][] = Array.from(trackPoints).map(trkpt => [
        Number(trkpt.getAttribute('lat')),
        Number(trkpt.getAttribute('lon')),
      ]);

      resolve(data);
    }
  };

  xmlhttp.open('GET', url, true);
  xmlhttp.send();
}

interface Props {
  disablePopup?: boolean;
  maxClusterRadius?: number;
  locations?: MapLocation[];
  showStoreMarker?: boolean;
  flatCards?: boolean;
  gpx?: {
    url: string;
  };
  size?: 'regular' | 'large' | 'store' | 'storeFixed';
}

export default function InteractiveMap({
  locations = [],
  gpx,
  disablePopup,
  maxClusterRadius,
  showStoreMarker = false,
  flatCards = false,
  size = 'regular',
}: Props) {
  const [route, setRoute] = useState<[number, number][]>([]);

  const LeafletMap = useMemo(
    () =>
      dynamic(() => import('@organisms/InteractiveMap/LeafletMap'), {
        loading: Loading,
        ssr: false,
      }),
    []
  );

  useEffect(() => {
    if (!gpx?.url) return;

    const getRoute = async () => {
      const data = await new Promise<[number, number][]>(resolve => {
        loadXMLDoc(gpx.url, resolve);
      });

      setRoute(data);
    };

    getRoute();
  }, [gpx?.url]);

  return (
    <div className={clsx(styles.container, styles[size])}>
      <LeafletMap
        locations={locations}
        route={route}
        disablePopup={disablePopup}
        maxClusterRadius={maxClusterRadius}
        showStoreMarker={showStoreMarker}
        flatCards={flatCards}
      />
    </div>
  );
}
