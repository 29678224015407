import 'flatpickr/dist/themes/material_red.css';

import useStore from '@hooks/useStore';
import clsx from 'clsx';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Options } from 'flatpickr/dist/types/options';
import { useCallback, useEffect, useRef, useState } from 'react';
import FlatPickr from 'react-flatpickr';

import styles from './DatePicker.module.scss';

interface Props {
  options?: Options;
  label: string;
  id?: string;
  name?: string;
  hideLabel?: boolean;
  required?: boolean;
}

export default function DatePicker({
  id,
  options = {},
  label,
  hideLabel,
  name,
  required = false,
}: Props) {
  const ref = useRef<FlatPickr>(null!);

  const {
    store: { locale },
  } = useStore();

  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (dates: Date[]) => {
    const date = dates[0]?.toISOString() || '';
    setInputValue(date);
  };

  const preventManualInput = () => {
    if (!ref.current || !required) return;
    ref.current.flatpickr.altInput?.setAttribute('readonly', 'readonly');
  };

  const enableNativeValidation = useCallback(() => {
    if (!ref.current || !required) return;
    ref.current.flatpickr.altInput?.removeAttribute('readonly');
  }, [required]);

  useEffect(() => enableNativeValidation(), [enableNativeValidation]);

  return (
    <div className={clsx(styles.wrapper, !!inputValue && styles.hasValue)}>
      {!hideLabel && !!id && (
        <label className={styles.label} htmlFor={id}>
          {label}
          {required && <span className={styles.asterix}> *</span>}
        </label>
      )}
      <FlatPickr
        ref={ref}
        value={inputValue}
        onChange={handleChange}
        id={id}
        name={name}
        aria-label={label}
        className={styles.input}
        required={required}
        options={{
          mode: 'single',
          locale: locale === 'nl' ? Dutch : undefined,
          dateFormat: 'd-m-Y',
          minDate: 'today',
          time_24hr: true,
          onOpen: preventManualInput,
          onClose: enableNativeValidation,
          ...options,
        }}
      />
    </div>
  );
}
