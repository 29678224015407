import Tag from '@atoms/Tags/Tag';
import useTranslations from '@hooks/useTranslations';
import { Image as ImageType } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './Figure.module.scss';

interface Props {
  image: ImageType;
  alt?: string;
  sizes?: string;
  caption?: string;
  author?: string;
  className?: string | false;
  imageClassName?: string | false;
  quality?: number;
  layout?: 'fill' | 'responsive' | 'intrinsic' | 'fixed';
  objectFit?: 'cover' | 'contain';
  objectPosition?: string;
  priority?: boolean;
}

function Figure({
  image,
  alt,
  sizes,
  caption,
  author,
  className,
  imageClassName,
  quality = 75,
  layout = 'fill',
  objectFit = 'cover',
  objectPosition = 'center',
  priority,
}: Props) {
  const t = useTranslations();
  const conditionalProps =
    layout === 'fill'
      ? { layout }
      : { layout, width: image.width, height: image.height };
  return (
    <figure className={clsx(styles.figure, className)}>
      <Image
        className={clsx(styles.image, imageClassName)}
        src={image.url}
        placeholder="blur"
        blurDataURL={image.placeholder}
        alt={alt || ''}
        sizes={sizes}
        quality={quality}
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...conditionalProps}
      />
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
      {author && (
        <Tag title={`${t.author}: ${author}`} className={styles.author} />
      )}
    </figure>
  );
}

export default Figure;
