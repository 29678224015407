import Icon from '@atoms/Icon/Icon';
import useTranslations from '@hooks/useTranslations';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';

import styles from './FileUpload.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id'> {
  maxSizeInMb?: number;
  label: string;
  helpText?: string;
  id: string; // ID is required for the label to work
}

const mbInBytes = 1048576;

export default function FileUpload({
  maxSizeInMb,
  disabled,
  required,
  helpText,
  accept,
  label,
  name,
  id,
}: Props) {
  const t = useTranslations();

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const uploadedFile = e.target.files?.length ? e.target.files[0] : null;

    if (
      maxSizeInMb &&
      uploadedFile &&
      uploadedFile.size > maxSizeInMb * mbInBytes
    ) {
      setError(t.fileToLarge);
      return;
    }

    setFile(uploadedFile);
    setError(null);
  }

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        accept={accept}
        type="file"
        name={name}
        id={id}
      />

      <label htmlFor={id} className={styles.label}>
        <Icon icon="UPLOAD" />
        {label}
      </label>

      {!!error && (
        <p className={styles.error}>
          <Icon icon="WARNING_CIRCLE" />
          {error}
        </p>
      )}

      {!error && (
        <p className={styles.fileName}>{file ? file.name : t.noFileSelected}</p>
      )}

      {helpText && <p className={styles.helpText}>{helpText}</p>}
    </div>
  );
}
