import Video from '@atoms/Video/Video';
import TextMediaWrapper from '@molecules/TextMediaWrapper/TextMediaWrapper';
import { TextVideoSection as Props } from '@type-declarations/sections';

import styles from './TextVideoSection.module.scss';

function TextVideoSection({
  title,
  subtitle,
  text,
  cta,
  backgroundColor,
  textLeft,
  video,
  modifier,
}: Props) {
  return (
    <TextMediaWrapper
      title={title}
      subtitle={subtitle}
      text={text}
      cta={cta}
      textLeft={textLeft}
      backgroundColor={backgroundColor.color}
      modifier={modifier === 'inStore' ? 'inStore' : undefined}
    >
      {(video?.embeddedVideo?.playbackId || video?.youtubeId) && (
        <Video video={video} className={styles.video} />
      )}
    </TextMediaWrapper>
  );
}

export default TextVideoSection;
